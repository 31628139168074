import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

// Import all images
import internaltax from "../../../src/assets/images/blogs/InternationalTaxEra1.png";
import fbar2 from "../../../src/assets/images/blogs/FBAR2.png";



const BlogMobile = () => {
  const [showMore, setShowMore] = useState(false);

  // Mobile Blog Card Component
  const MobileBlogCard = ({ to, image, date, title }) => {
    return (
      <Link 
        to={to} 
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          borderRadius: "0.5rem",
          overflow: "hidden",
          marginBottom: "1rem",
          textDecoration: "none",
          color: "inherit"
        }}
      >
        <div 
          style={{ 
            height: "12rem",
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}
        />
        <div style={{ padding: "1rem" }}>
          <div style={{ 
            fontSize: "0.875rem", 
            color: "#6b7280", 
            marginBottom: "0.5rem" 
          }}>
            {date}
          </div>
          <h3 style={{ 
            fontSize: "1.125rem", 
            fontWeight: "600", 
            color: "#1f2937",
            margin: "0"
          }}>
            {title}
          </h3>
        </div>
      </Link>
    );
  };

  // Blog posts data
  const mobileBlogPosts = [
    {
      to: "/international-tax-era",
      image: internaltax,
      date: "Mar 15, 2025",
      title: "Managing the New Global Minimum International Tax Era: Essential Facts for Multinationals"
    },
    {
      to: "/fbar-penalties-revealed",
      image: fbar2,
      date: "Mar 10, 2025",
      title: "FBAR Penalties Revealed: What Every Foreign Investor Needs to Know"
    }
  ];

  // Additional blog posts for "Show More" functionality
  const additionalMobileBlogPosts = [
    // Add more blog posts here if needed
  ];

  return (
    <div style={{ 
      display: "block", 
      padding: "1rem"
    }}>
      <div style={{ 
        paddingBottom: "1rem", 
        marginBottom: "1rem", 
        textAlign: "center" 
      }}>
        <h3 style={{ 
          fontSize: "1.5rem", 
          fontWeight: "700", 
          color: "#072f5f", 
          marginTop: "90px" 
        }}>
          Insights from Boston Financial
        </h3>
      </div>

      <div style={{ marginBottom: "1rem" }}>
        {mobileBlogPosts.map((post, index) => (
          <MobileBlogCard 
            key={index}
            to={post.to}
            image={post.image}
            date={post.date}
            title={post.title}
          />
        ))}

        {showMore && additionalMobileBlogPosts.map((post, index) => (
          <MobileBlogCard 
            key={index}
            to={post.to}
            image={post.image}
            date={post.date}
            title={post.title}
          />
        ))}
      </div>

      <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Link to="/blogs">
            <Button
              className="buttonUnique read-more-button"
              variant="contained"
              color="primary"
              style={{ 
                backgroundColor: "#072f5f",
                padding: "8px 24px",
                fontSize: "16px",
                minWidth: "120px"
              }}
            >
              Read More
            </Button>
          </Link>
        </div>
    </div>
  );
};

export default BlogMobile;